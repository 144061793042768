<script>
export default {
    data() {
        return {
            
        };   
    },
    props: {
        current_status: {},
        fields: {},
        items: {},
        heading: {},
        errors: {}
    },
    mounted() {
        //
    },
    async created() {
    },
    methods: {
        displayObject(val) {
            let itemVal;
            if (val.includes('.')) {
                itemVal = val.split('.').reduce((o, p) => o && o[p], this.items);
                
                return this.mapNull(itemVal);
            }
            
            return this.mapNull(this.items[val]);
        },
        mapNull(val) {
            if (val === "" || val === null || val === undefined) {
                return "-";
            }
            if (val === 1 || val === 0) {
                return val === 1 ? "Yes" : "No";
            }
            return val;
        },
    },
};
</script>
<template>
    <table class="tb">
        <tr>
            <th colspan="2" class="main_heading"><slot name="heading">{{heading}}</slot></th>
        </tr>
        <tr class="content" v-for="field in fields" :key="field.key">
            <th class="table__label">{{ field.label }}</th>
            <td class="content-with-error">
                <div style="width: calc(100% - 25px);" class="error-content">
                    <slot :name="field.key">
                        {{ 
                            field.key === 'rental_deposit' || field.key === 'utility_deposit' 
                            ? 'RM ' + displayObject(field.key) 
                            : displayObject(field.key) 
                        }}
                    </slot>
                    <b-form-invalid-feedback id="input-live-feedback" :state="false" v-if="errors && errors.hasOwnProperty(field.key)">
                        {{ errors[field.key][0] }}
                    </b-form-invalid-feedback>
                </div>
                <span style="width: 25px; vertical-align: middle; display: inline-block;" v-if="errors">
                    <i class="ri-close-circle-fill" style="float: right; color: red;" v-show="errors.hasOwnProperty(field.key)"></i>
                </span>
            </td>
        </tr>
    </table>
</template>

<style lang="scss" scoped>
.tb {
    width: 100%;
    table-layout: fixed;

    tr, td, th {
        border: 1px solid #2D37484D;
    }

    td, th {
        padding: 10px;
    }

    .main_heading {
        background: #E8ECF0;
        font-family: "Nunito Sans";
        font-size: 14px;
        font-weight: 900;
        line-height: 18.2px;
        text-align: left;
    }

    .content {
        th {
            width: 50%;
            font-family: "Nunito Sans";
            font-weight: 600;
            font-size: 14px;
            line-height: 18.2px;
            text-align: left;
            color: black;
        }

        td {
            width: 50%;
            font-family: "Nunito Sans";
            font-size: 14px;
            font-weight: 500;
            line-height: 18.2px;
            text-align: left;
            color: black;
        }
    }
}

.content-with-error {
    .error-content {
        vertical-align: middle;
        display: inline-block;
        font-family: "Nunito Sans";
        font-size: 14px;
    }
}
</style>